/* QuestionDetail.module.css */

.container {
    max-width: 800px;
    margin: 40px auto;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: var(--primary-color, #333333);
  }
  
  .image {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto 20px;
    border-radius: 8px;
  }
  
  .questionSection {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .questionSection h2 {
    font-size: 1.5rem;
    color: var(--accent-color, #D97706);
    margin-bottom: 10px;
  }
  
  .answersSection {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .answersSection h3 {
    font-size: 1.4rem;
    color: var(--accent-color, #D97706);
    margin-bottom: 10px;
  }
  
  .answersSection ul {
    list-style: none;
    padding: 0;
  }
  
  .answersSection li {
    background-color: #f7f7f7;
    border: 1px solid #dddddd;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.2s ease;
  }
  
  .answersSection li:hover {
    background-color: #e9ecef;
  }
  
  .correct {
    background-color: #d4edda;
    border-color: #c3e6cb;
    font-weight: bold;
  }
  
  .legalSection {
    margin-bottom: 20px;
    font-size: 0.95rem;
    color: #555555;
  }
  
  .legalSection p {
    margin: 5px 0;
  }
  
  .explanationSection {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .explanationSection h3 {
    font-size: 1.4rem;
    color: var(--accent-color, #D97706);
    margin-bottom: 10px;
  }
  
  .actionSection {
    text-align: center;
    font-size: 1rem;
    color: var(--secondary-color, #666666);
    padding-top: 20px;
    border-top: 1px solid #eeeeee;
  }
  